.container {
  font-family: Arial, sans-serif;
}

.header {
  background-color: #0055A4; /* This is a hypothetical primary blue color from Special Olympics. Replace with the actual color. */
  color: white;
  padding: 20px 0;
  text-align: center;
}

.mainTitle {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.userSection {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.signOutBtn {
  background-color: #EF4135; /* This is a hypothetical red color from Special Olympics. Replace with the actual color. */
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
}

.ownerIds {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

.ownerIds h2 {
  font-size: 1rem; /* Reducing the size from the default h2 size */
  margin: 0;  /* Remove default margins */
}



.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  padding-left: 0;
  margin: 20px 0;
}

.nav-links li a {
  text-decoration: none;
  color: #0055A4; /* Hypothetical blue color */
  padding: 5px 10px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.nav-links li a:hover {
  background-color: #EF4135; /* Hypothetical red color */
  color: white;
}

.App-header {
  padding: 20px;
  background-color: #F1F1F1; /* Light gray background */
}
